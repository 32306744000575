.circle-ripple-effect {
	border-radius: 50%;
	position: relative;
	&::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		animation: ripplexxx 1.2s infinite ease-in-out;
		border: 1px solid currentColor;
		content: ' ';
	}
}

@keyframes ripplexxx {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(2.4);
		opacity: 0;
	}
} ;
